import React, { useEffect, useState } from 'react';
import { getAllPosts, getNextPosts } from '../../actions/feedAction';
import './posts.css';
import Post from './post';

const Feed = () => {
  const [posts, setPosts] = useState([]);
  const [lastPostId, setLastPostId] = useState('');
  const [filter, setFilter] = useState('showAll');

  const fetchPosts = async (fetchAll = true) => {
    try {
      const response = fetchAll ? await getAllPosts() : await getNextPosts(lastPostId);
      const fetchedPosts = response?.data || [];
      const filteredPosts =
        filter === 'approved' ? fetchedPosts.filter((post) => post?.status === 'approved') : fetchedPosts;

      if (!fetchAll) {
        setPosts((prevPosts) => [...prevPosts, ...filteredPosts]);
      } else {
        setPosts(filteredPosts);
      }
      setLastPostId(response?.last_post_id);
    } catch (error) {
      console.log('Error in fetching feeds:', error);
    }
  };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
      fetchPosts(false);
    }
  };

  const handleShowAll = () => {
    setFilter('showAll');
  };

  const handleApproved = () => {
    setFilter('approved');
  };

  useEffect(() => {
    fetchPosts(true);
  }, [filter]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastPostId]);

  return (
    <div className="app">
      <h1>Posts</h1>
      <label htmlFor="filter">Filter: </label>
      <select
        onChange={(e) => {
          if (e.target.value === 'showAll') {
            handleShowAll();
          } else if (e.target.value === 'approved') {
            handleApproved();
          }
        }}
        value={filter}
      >
        <option value="showAll">Show All</option>
        <option value="approved">Approved</option>
      </select>

      <div className="posts">
        {posts.map((post) => (
          <Post
            key={post.id}
            post={post}
            afterPostComment={() => fetchPosts(true)}
            afterPostLike={() => fetchPosts(true)}
            afterPostAction={() => {
              fetchPosts(true);
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Feed;
